import React from 'react'

function useInterval(callback, delay) {
  const savedCallback = React.useRef();

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = window.setInterval(tick, delay);
      return () => window.clearInterval(id);
    }
  }, [delay]);
}


export default function ({showPhoto, updaterate, size, onNewImage, imgType}) {
    
    updaterate = updaterate || 10000
    
    imgType = imgType || "jpg"
    const width = size || 320;
        
    const videoRef = React.useRef(null);
    const canvasRef = React.useRef(null);
    const photoRef = React.useRef(null);
    const startButtonRef = React.useRef(null);

    
    const [calculatedHeight, setHeight] = React.useState(0)

    const [streaming, setStreaming] = React.useState(false)
    
    const takepicture = ()=>{
        // For readability
        const video = videoRef.current
        const canvas = canvasRef.current
        const photo = photoRef.current
        const startButton = startButtonRef.current

        if ( width && calculatedHeight && canvas && video ) {
          const context = canvas.getContext('2d');
          canvas.width = width;
          canvas.height = calculatedHeight;
          context.drawImage(video, 0, 0, width, calculatedHeight);
        
          //const data = canvas.toDataURL('image/jpeg');
          canvas.toBlob((blob)=>{
              if (onNewImage) 
              {
                onNewImage(blob)
              }
              if (photo)
              {
                photo.setAttribute('src', blob);
              }                            
          }, 'image/'+imgType);
        } else {
          clearphoto();
        }        
        
    }
    
    useInterval(()=>{        
        takepicture()
    },updaterate)
    
    const clearphoto = ()=>{
        // For readability
        const video = videoRef.current
        const canvas = canvasRef.current
        const photo = photoRef.current

        if (canvas)
        {
            const context = canvas.getContext('2d');
            context.fillStyle = "#AAA";
            context.fillRect(0, 0, canvas.width, canvas.height);

            const data = canvas.toDataURL('image/png');
            if (onNewImage) 
            {
                onNewImage(data)
            }
            if (photo)
            {
                photo.setAttribute('src', data);
            }
        }
      }
      
    React.useEffect(()=>
    {
        videoRef.current.addEventListener('canplay', function(ev){
            let newHeight = Math.round(videoRef.current.videoHeight / (videoRef.current.videoWidth/width));
            if (calculatedHeight != newHeight)
            {
                setHeight(newHeight)
            }
        }, false);
        
        
        window.navigator.mediaDevices.getUserMedia({ video: true, audio: false })
        .then(function(stream) {
            videoRef.current.srcObject = stream;
            videoRef.current.play();
        })
        .catch(function(err) {
            console.log("An error occurred: " + err);
        });
       
        
        clearphoto()
        
        // Cleanup function
        return ()=>{
          if (videoRef.current && videoRef.current.srcObject )
          {
            videoRef.current.srcObject.getTracks()[0].stop();
          }
        }
    },[videoRef])

   
    React.useEffect(()=>
    {
        const video = videoRef.current
        const canvas = canvasRef.current
        
        video.setAttribute('width', width);
        video.setAttribute('height', calculatedHeight);
        canvas.setAttribute('width', width);
        canvas.setAttribute('height', calculatedHeight);
    }, [calculatedHeight])
    
    const hidden = {display:"none"}
    return <div>
    <video style={hidden} ref={videoRef}/>
    <canvas style={hidden} ref={canvasRef}/>
    {showPhoto?
      <div >
        <img ref={photoRef} alt="The screen capture will appear in this box."/>
      </div>
    : ""}
  
    </div>
}