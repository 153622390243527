import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const ConfirmDialog = (props) => {
  const { title, children, open, setOpen } = props;
  return (
    <Dialog
      open={Boolean(open)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
              if (open.onNo)
              {
                  open.onNo()
              }
              setOpen(null)
          }}
          color="secondary"
        >
          No
        </Button>
        <Button
          variant="contained"
          onClick={() => {
              if (open.onYes)
              {
                  open.onYes()
              }
              setOpen(null)
          }}
          color="default"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;