import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import querystring from "querystring";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

var q = {};
try {
 q = querystring.parse(window.location.search.substring(1)); // Skip the "?"
}
catch(e)
{}

const useStyles = makeStyles(styles);

export default function LoginPage({ onApply, onResetPassword }) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [username, setUsername] = React.useState(q.email?q.email.toLowerCase():"");
  const [pw, setPw] = React.useState("");
  const [resetPassword, setResetPassword] = React.useState(false);
  
  const doAction = () =>
  {
	if (resetPassword)
	{
		onResetPassword(username);
	}
	else
	{
		onApply(username, pw); 
	}
  };
  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="primary"
              >
                <h4 className={classes.cardTitle}>Student Log in</h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Email..."
                  id="email"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    onChange: e => setUsername(e.target.value.toLowerCase()),
                    defaultValue: username
					}}
                />
				{!resetPassword && (
                <CustomInput
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
				    onChange: e => setPw(e.target.value),
					onKeyDown: e => { if (e.key === 'Enter') { doAction(); } }
                  }}
				 />
				)}
				<span><Checkbox color="primary"
                    tabIndex={-1}
                    onClick={event => {
                      if (event.target.checked) {
                        setResetPassword(true);
                      } else {
                        setResetPassword(false);
                      }
                    }}
                  />Reset Password</span>
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button color="primary" simple size="lg" block onClick={ () => doAction() }>
					{resetPassword? "Reset Password" : "Login"}
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
